import { createAsyncThunk } from "@reduxjs/toolkit";
import repo from 'shared/repository'
import { SubscriptionCase, CaseDelivery } from "shared/types";
import { validateServerError } from "shared/utils";

export const getSubs = createAsyncThunk('/subscriptions/get', async (_, { rejectWithValue }) => {
  try {
    const response = await repo.get('/subscriptions');
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
})

export const getPaymentMethods = createAsyncThunk('/payment-methods/get', async (_, { rejectWithValue }) => {
  try {
    const response = await repo.get('/users/payment-methods');
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
})

export const cancelSubscription = createAsyncThunk('/subscription/cancel', async (subId: string, { rejectWithValue }) => {
  try {
    const response = await repo.post(`/subscriptions/${subId}/cancel`);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
})

export type RefundSubscriptionType = { subscriptionId: string, amount: number }
export const refundSubscription = createAsyncThunk('/subscription/refund', async (values: RefundSubscriptionType, { rejectWithValue }) => {
  try {
    const { subscriptionId, amount } = values;
    const response = await repo.post(`/subscriptions/${subscriptionId}/refund?amount=${amount}`);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
})

export const updateSubscriptionsDeliveries = createAsyncThunk(
  '/subscriptions/deliveries/update',
  async (deliveries: CaseDelivery[], { rejectWithValue }) => {
    try {
      // const response = await repo.put('/subscriptions/cases/deliveries', data);
      const response = await Promise.all(deliveries.map(async ({ caseDeliveryId, deliveryPrice }) => {
        return repo.put(`/cases/delivery/${caseDeliveryId}`, { deliveryPrice })
          .then((r) => r.data as CaseDelivery)
      }))
      return response;
    } catch (error: any) {
      return rejectWithValue(validateServerError(error));
    }
  })

export const getSubscriptionsCases = createAsyncThunk('/subscriptions/cases/get', async (_, { rejectWithValue }) => {
  try {
    const response = await repo.get('/cases');
    return response.data as SubscriptionCase[];
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
});

export const updateSubscriptionsCase = createAsyncThunk('/subscriptions/case/update', async (subCase: SubscriptionCase, { rejectWithValue }) => {
  try {
    const response = await repo.put(
      `/case/${subCase.caseId}`,
      {
        caseAmount: subCase.caseAmount,
        casePrice: subCase.casePrice
      }
    );
    return response.data;
  } catch (error: any) {
    return rejectWithValue(validateServerError(error));
  }
});

export const getSubscriptionsCasesDeliveries = createAsyncThunk(
  '/subscriptions/cases/deliveries/get',
  async (_, { rejectWithValue }) => {
    try {
      const response = await repo.get('/cases/deliveries');
      return response.data as CaseDelivery[];
    } catch (error: any) {
      return rejectWithValue(validateServerError(error));
    }
  }
)