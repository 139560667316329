import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderItem, ServerError } from 'shared/types';
import {
  cancelAndRefundOrder,
  confirmOrderCollected,
  confirmOrderDelivery,
  confirmOrderReadyForPickUp,
  confirmOrderShipping,
  createOrderDelivery,
  getOrderDeliveries,
  getOrders,
  returnOrder,
  updateOrderDelivery
} from 'store/actions/orders-action';
import { OrdersState } from 'store/types';

const initialState: OrdersState = {
  orders: [],
  delivery: null,
  loading: {
    getOrders: false,
    canceledOrder: false,
    readyForPickUpOrder: false,
    collectedOrder: false,
    shippedOrder: false,
    deliveredOrder: false,
    returnedOrder: false,
    getDelivery: false,
    updateDelivery: false,
  },
  newOrdersAmount: 0,
  error: null,
};

const ordersReducer = createSlice({
  name: 'products',
  initialState,
  reducers: {
    addNewOrders: (state, { payload }: PayloadAction<OrderItem[]>) => {
      state.orders = [ ...payload, ...state.orders ];
    },
    incrementNewOrdersAmount: (state, { payload }: PayloadAction<number | undefined>) => {
      state.newOrdersAmount = typeof payload === 'number' ? payload : state.newOrdersAmount + 1;
    }
  },
  extraReducers: (builder) => {
    builder
      // ============ GET ORDERS ============ //
      .addCase(getOrders.pending, (state) => {
        state.loading.getOrders = true;
        state.error = null;
      })
      .addCase(getOrders.fulfilled, (state, { payload }) => {
        state.loading.getOrders = false;
        state.orders = payload;
      })
      .addCase(getOrders.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.getOrders = false;
        state.error = action.payload.message;
      })
      // ============ CANCEL ORDER ============ //
      .addCase(cancelAndRefundOrder.pending, (state) => {
        state.loading.canceledOrder = true;
        state.error = null;
      })
      .addCase(cancelAndRefundOrder.fulfilled, (state, { payload }) => {
        state.loading.canceledOrder = false;
        for (let i = 0; i < state.orders.length; i++) {
          if (state.orders[i].orderId === payload.orderId) {
            state.orders[i] = payload;
            break;
          }
        }
      })
      .addCase(cancelAndRefundOrder.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.canceledOrder = false;
        state.error = action.payload.message;
      })
      // ============ CONFIRM ORDER READY FOR PICK-UP ============ //
      .addCase(confirmOrderReadyForPickUp.pending, (state) => {
        state.loading.readyForPickUpOrder = true;
        state.error = null;
      })
      .addCase(confirmOrderReadyForPickUp.fulfilled, (state, { payload }) => {
        state.loading.readyForPickUpOrder = false;
        for (let i = 0; i < state.orders.length; i++) {
          if (state.orders[i].orderId === payload.orderId) {
            state.orders[i] = payload;
            break;
          }
        }
      })
      .addCase(confirmOrderReadyForPickUp.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.readyForPickUpOrder = false;
        state.error = action.payload.message;
      })
      // ============ CONFIRM ORDER COLLECTED ============ //
      .addCase(confirmOrderCollected.pending, (state) => {
        state.loading.collectedOrder = true;
        state.error = null;
      })
      .addCase(confirmOrderCollected.fulfilled, (state, { payload }) => {
        state.loading.collectedOrder = false;
        for (let i = 0; i < state.orders.length; i++) {
          if (state.orders[i].orderId === payload.orderId) {
            state.orders[i] = payload;
            break;
          }
        }
      })
      .addCase(confirmOrderCollected.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.collectedOrder = false;
        state.error = action.payload.message;
      })
      // ============ CONFIRM ORDER SHIPPING ============ //
      .addCase(confirmOrderShipping.pending, (state) => {
        state.loading.shippedOrder = true;
        state.error = null;
      })
      .addCase(confirmOrderShipping.fulfilled, (state, { payload }) => {
        state.loading.shippedOrder = false;
        for (let i = 0; i < state.orders.length; i++) {
          if (state.orders[i].orderId === payload.orderId) {
            state.orders[i] = payload;
            break;
          }
        }
      })
      .addCase(confirmOrderShipping.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.shippedOrder = false;
        state.error = action.payload.message;
      })
      // ============ CONFIRM ORDER DELIVERY ============ //
      .addCase(confirmOrderDelivery.pending, (state) => {
        state.loading.deliveredOrder = true;
        state.error = null;
      })
      .addCase(confirmOrderDelivery.fulfilled, (state, { payload }) => {
        state.loading.deliveredOrder = false;
        for (let i = 0; i < state.orders.length; i++) {
          if (state.orders[i].orderId === payload.orderId) {
            state.orders[i] = payload;
            break;
          }
        }
      })
      .addCase(confirmOrderDelivery.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.deliveredOrder = false;
        state.error = action.payload.message;
      })
      // ============ RETURN ORDER ============ //
      .addCase(returnOrder.pending, (state) => {
        state.loading.returnedOrder = true;
        state.error = null;
      })
      .addCase(returnOrder.fulfilled, (state, { payload }) => {
        state.loading.returnedOrder = false;
        for (let i = 0; i < state.orders.length; i++) {
          if (state.orders[i].orderId === payload.orderId) {
            state.orders[i] = payload;
            break;
          }
        }
      })
      .addCase(returnOrder.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.returnedOrder = false;
        state.error = action.payload.message;
      })
      // ============ GET ORDER DELIVERY ============ //
      .addCase(getOrderDeliveries.pending, (state) => {
        state.loading.getDelivery = true;
        state.error = null;
      })
      .addCase(getOrderDeliveries.fulfilled, (state, { payload }) => {
        state.loading.getDelivery = false;
        state.delivery = payload?.pop() || null;
      })
      .addCase(getOrderDeliveries.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.getDelivery = false;
        state.error = action.payload.message;
      })
      // ============ UPDATE ORDER DELIVERY ============ //
      .addCase(updateOrderDelivery.pending, (state) => {
        state.loading.updateDelivery = true;
        state.error = null;
      })
      .addCase(updateOrderDelivery.fulfilled, (state, { payload }) => {
        state.loading.updateDelivery = false;
        state.delivery = payload;
      })
      .addCase(updateOrderDelivery.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.updateDelivery = false;
        state.error = action.payload.message;
      })

      // ============ CREATE ORDER DELIVERY ============ //
      .addCase(createOrderDelivery.pending, (state) => {
        state.loading.updateDelivery = true;
        state.error = null;
      })
      .addCase(createOrderDelivery.fulfilled, (state, { payload }) => {
        state.loading.updateDelivery = false;
        state.delivery = payload;
      })
      .addCase(createOrderDelivery.rejected, (state, action: any & { payload: ServerError }) => {
        state.loading.updateDelivery = false;
        state.error = action.payload.message;
      })
  },
});

export const { addNewOrders, incrementNewOrdersAmount } = ordersReducer.actions;
export default ordersReducer.reducer;
