import { Conversation, Message } from '@twilio/conversations';
import { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { ReduxConversation, ReduxMessage, ReduxMessageMedia } from 'store/reducers/chat-reducer';
import { SessionToken } from 'store/types';
import {
  MeasurementType,
  Notification,
  OrderStates,
  PaginationType,
  PersonalInfoUpdate,
  ProductItem,
  ProductItemBundled,
  ProductStock,
  ServerError,
  SubscriptionInterval,
  SubscriptionRecurring,
  UnitsType,
  UserInfo,
} from './types';
import { v4 as uuid_v4 } from 'uuid';

import 'jimp';
import * as jimp from 'jimp';
import { Area } from 'react-easy-crop/types';
declare const Jimp: typeof jimp;

export const validateServerError = (error: any | AxiosError) => {
  if (error?.response?.data as ServerError) {
    return error.response.data as ServerError;
  }

  return {
    message: error.message,
  } as ServerError;
};

export const getDecodedToken = (): SessionToken | null => {
  let access_token = null;
  try {
    access_token = jwtDecode(cookies.get('access_token') || '');
  } catch (error) {
    cookies.remove('access_token');
  }
  return access_token as SessionToken | null;
};

export const paginate = <T>(data: T[], pagination: PaginationType): T[] => {
  const { perPage, page } = pagination;
  const start = (page - 1) * perPage;
  return data.slice(start, start + perPage);
};

export const paginationBalancer = (pagination: PaginationType): PaginationType => {
  const { total, perPage, page } = pagination;
  const maxPages = Math.ceil(total / perPage);
  const correctPage = page > maxPages ? maxPages : page;
  return { total, perPage, page: correctPage };
};

export const getUnitValue = (units: UnitsType, measurment: MeasurementType) => {
  switch (units) {
    case UnitsType.LENGTH:
      switch (measurment) {
        case MeasurementType.IMP:
          return 'ft'; // foot
        case MeasurementType.MTC:
          return 'm'; // centimeter
      }
    case UnitsType.WEIGHT:
      switch (measurment) {
        case MeasurementType.IMP:
          return 'lbs'; // pound
        case MeasurementType.MTC:
          return 'kg'; // kilogram
      }
  }
};

export const getMesurmentName = (measurment: MeasurementType) => {
  switch (measurment) {
    case MeasurementType.IMP:
      return 'imperial';
    case MeasurementType.MTC:
      return 'metric';
  }
};

export const getFeetWithInches = (value: number) => {
  const ft = Math.floor(value);
  const inch = Math.round((value - ft) * 12);
  return `${ft}'` + (inch ? `${inch}''` : '');
};

export const getAllUniqueProductCategories = (products: ProductItemBundled[]): string[] => {
  const list: string[] = [];
  products.forEach((product) => {
    product.categories.forEach((category) => {
      const founded = list.find((item) => item === category);
      if (!founded) {
        list.push(category);
      }
    });
  });
  return list;
};

export const getUserPersonalInfoUpdates = (user: UserInfo, updates: any): PersonalInfoUpdate => ({
  birth: updates?.birth || user.birth,
  weight: updates?.weight || user.weight,
  height: updates?.height || user.height,
  gender: updates?.gender || user.gender,
  activity: updates?.activity || user.activity,
  climate: updates?.climate || user.climate,
  activityDuration: updates?.activityDuration || user.activityDuration,
  firstName: updates?.firstName || user.firstName,
  lastName: updates?.lastName || user.lastName,
  measurement: updates?.measurement || user.settings.measurement,
});

export const getOrderStatusSortOrder = (status: OrderStates) => {
  switch (status) {
    case OrderStates.PREPARING:
      return 1;
    case OrderStates.RETURN_REQUESTED:
      return 2;
    case OrderStates.READY_FOR_PICK_UP:
      return 3;
    case OrderStates.SHIPPING:
      return 4;
    case OrderStates.COLLECTED:
      return 5;
    case OrderStates.DELIVERED:
      return 6;
    case OrderStates.CANCELLED_ADMIN:
      return 7;
    case OrderStates.CANCELLED_USER:
      return 8;
    default:
      return 0;
  }
};

export const fromProductToBundle = (product: ProductItem): ProductItemBundled => ({
  name: product.name,
  displayName: product.displayName,
  description: product.description,
  categories: product.categories,
  price: product.price,
  image: product.image,
  created: product.created,
  updated: product.updated,
  loyaltyPointsPrice: product.loyaltyPointsPrice,
  stock: [
    {
      isAvailable: product.isAvailable,
      size: product.size,
      quantity: product.quantity,
    },
  ],
});

export const getProductBundle = (products: ProductItem[]): ProductItemBundled[] => {
  const bundledProducts: ProductItemBundled[] = [];

  products.forEach((product) => {
    const existedIndex = bundledProducts.findIndex((bundled) => bundled.name === product.name);
    if (existedIndex === -1) {
      bundledProducts.push(fromProductToBundle(product));
    } else {
      bundledProducts[existedIndex].stock.push({
        isAvailable: product.isAvailable,
        size: product.size,
        quantity: product.quantity,
      });
    }
  });

  return bundledProducts;
};

export const isAtLeastOneAvailiable = (stock: ProductStock[]) => !stock.every(({ isAvailable }) => !isAvailable);

export const getSumQuantity = (stock: ProductStock[]) => stock.reduce((accum, { quantity }) => accum + quantity, 0);

export const usPhoneNumberMask = (phone: string) => {
  var arr = phone
    .replaceAll('+1', '')
    .replaceAll(/\D/g, '')
    .match(/(\d{3})(\d{3})(\d{4})/);
  if (!arr) return null;
  return `(${arr[1]}) ${arr[2]} - ${arr[3]}`;
};

export const priceFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
});

export const getSubscriptionTypeByReccuring = ({ interval, intervalCount }: SubscriptionRecurring) => {
  if (!interval || !intervalCount) return '';
  if (interval === SubscriptionInterval.MONTH && intervalCount === 1) {
    return 'monthly';
  }
  if (interval === SubscriptionInterval.WEEK && intervalCount === 1) {
    return 'weekly';
  }
  if (interval === SubscriptionInterval.WEEK && intervalCount === 2) {
    return 'biweekly';
  }
  return '';
};

export const sortChatConversations = (conversations: ReduxConversation[]) => {
  const withUnreadMessages: ReduxConversation[] = [];
  const withReadMessages: ReduxConversation[] = [];
  for (const conv of conversations) {
    const array = conv.unreadMessagesCount ? withUnreadMessages : withReadMessages;
    array.push(conv);
  }
  return [
    ...withUnreadMessages.sort((a, b) => (b.lastMessage.created || 0) - (a.lastMessage.created || 0)),
    ...withReadMessages.sort((a, b) => (b.lastMessage.created || 0) - (a.lastMessage.created || 0)),
  ];
};

export const twilioConversationToReduxConversation = async (twilioConv: Conversation) => {
  const lastMessage = (await twilioConv.getMessages(1)).items.pop();
  const lastMessageBody = lastMessage?.body;
  const lastMessageMedia = lastMessage?.attachedMedia?.map((media) => media.filename)?.join(', ');
  const reduxConversation: ReduxConversation = {
    sid: twilioConv.sid,
    name: twilioConv.friendlyName,
    unreadMessagesCount: (await twilioConv.getUnreadMessagesCount()) || 0,
    lastMessage: {
      created: twilioConv.lastMessage?.dateCreated?.valueOf() || null,
      body: lastMessageBody || lastMessageMedia || null,
    },
  };
  return reduxConversation;
};

export const twilioMessageToReduxMessage = async (twilioMessage: Message) => {
  const adminIdentity = jwtDecode<any>(Cookies.get('twilio_token') || '')?.grants?.identity;
  const reduxMessage: ReduxMessage = {
    sid: twilioMessage.sid,
    conversationSid: twilioMessage.conversation.sid,
    author: twilioMessage.author,
    isAdminMessage: adminIdentity === (await twilioMessage.getParticipant()).identity,
    body: twilioMessage.body,
    created: twilioMessage.dateCreated?.valueOf() || null,
    attachedMedia: await Promise.all(
      twilioMessage.attachedMedia?.map(
        async (media): Promise<ReduxMessageMedia> => ({
          url: await media.getContentTemporaryUrl(),
          filename: media.filename,
        }),
      ) || [],
    ),
  };
  return reduxMessage;
};

export const cropImage = async (fileBase64: string, { x, y, width, height }: Area) => {
  try {
    const base64Data = fileBase64.replace(/^data:image\/\w+;base64,/, "");
    const buffer = Buffer.from(base64Data, 'base64');
    const image = await Jimp.read(buffer);
    image.crop(x, y, width, height);
    const croppedBase64 = await image.getBase64Async(Jimp.MIME_PNG);
    return {
      success: true,
      data: croppedBase64,
    };
  } catch (error: any) {
    console.error(error);
    return {
      success: false,
      message: error.mesage,
    }
  }
}
export const isBase64 = (file: string) => RegExp(/^data:image\/\w+;base64,/).test(file);

export const base64ToBlob = async (base64: string) => {
  const response = await fetch(base64);
  const blob = await response.blob();
  return blob;
};

export const getDeviceID = () => {
  let devideId = localStorage.getItem('device_id');
  if (!devideId) {
    devideId = uuid_v4();
    localStorage.setItem('device_id', devideId);
  }
  return devideId;
}

export const parseNotification = (raw: any): Notification | null => {
  if (!raw?.body || !raw?.title) return null;
  const parsed: Notification = {
    title: raw.title,
    body: JSON.parse(raw.body),
  }
  return parsed;
}